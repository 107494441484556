import {
  CohortSessionReportDetails,
  SessionReportModal_SessionReportModalDataFragment,
  UserLink,
} from "@generated/graphql";
import clsx from "clsx";
import { Icon, Tooltip } from "components/shared";
import { format } from "date-fns"; // Assuming you're using date-fns for formatting
import { getNormalizedReadableDate } from "helpers/dateText";
import { CohortEventInfo } from "../../../../../CohortEventInfo";
import { UserLogDetails } from "./UserLogDetails";

type Props = {
  readOnly: boolean;
  sessionDetails: CohortSessionReportDetails;
  sessionReport?: SessionReportModal_SessionReportModalDataFragment["sessionReport"];
};

export const ModalHeader = ({
  readOnly,
  sessionDetails,
  sessionReport,
}: Props) => {
  const {
    subject,
    cohortId,
    tutorLink,
    cohortName,
    subSubject,
    mentorLinks,
    endDateTime,
    engagementId,
    startDateTime,
    engagementName,
    substituteLinks,
    instructionType,
    instructionLevel,
    engagementProductType,
  } = sessionDetails || {};

  const renderUserList = (title: string, userNames: UserLink[]) => {
    const hasMultipleNames = userNames.length > 1;
    let firstName = userNames.length > 0 ? userNames[0].name : "Unknown";
    if (hasMultipleNames) firstName = `${firstName}...`;

    return (
      <Tooltip
        disabled={!hasMultipleNames}
        tooltipProps={{ place: "right" }}
        className={clsx(hasMultipleNames && "cursor-pointer")}
        content={
          <div className="flex flex-wrap text-sm font-medium text-center max-w-[350px] leading-5">
            {userNames.map(({ name }) => name).join(", ")}
          </div>
        }
      >
        <div className="flex items-center gap-x-2 text-base leading-none">
          <p className="font-bold text-slate-700">{title}:</p>
          <p className="text-cyan-800 font-semibold">{firstName}</p>
        </div>
      </Tooltip>
    );
  };

  return (
    <div className="flex flex-col w-full text-cyan-950/95 h-full relative">
      <div className="flex justify-between h-full">
        <div className="flex flex-col">
          <div className="flex flex-1 items-center gap-x-[14px]">
            <div
              className={clsx(
                "flex flex-center w-10 h-10 md:w-[60px] md:h-[60px] rounded-full",
                "bg-[linear-gradient(148deg,_#172554_50%,_#164e63_50%)]"
              )}
            >
              <Icon icon="sessionReport" size={6} color="text-cyan-50" />
            </div>

            <div className="relative flex flex-1 flex-col">
              <h1 className="text-4xl font-extrabold">Session Report</h1>
            </div>
          </div>

          <div className="flex flex-col mt-6 mb-7 ml-3 gap-y-2">
            {renderUserList("Teacher-Tutor", tutorLink ? [tutorLink] : [])}
            {renderUserList("Mentor Teacher", mentorLinks ?? [])}
            {renderUserList("Instructional Support", substituteLinks ?? [])}
          </div>
        </div>

        <div className="flex flex-col items-end justify-between h-full mr-4 pb-7">
          <div className="flex flex-col items-end text-xl font-extrabold leading-none gap-y-1">
            {getNormalizedReadableDate(new Date(startDateTime))}
            <div className="text-base text-cyan-700 leading-none">
              {`${format(startDateTime ?? 0, "h:mm a")} - ${format(
                endDateTime ?? 0,
                "h:mm	a"
              )}`}
            </div>
            {instructionType !== "Default" && (
              <span className="text-base text-cyan-900 font-bold leading-none">
                {instructionType}
              </span>
            )}
          </div>

          <div className="flex flex-col gap-y-1.5">
            {!readOnly && (
              <UserLogDetails
                className="items-end"
                sessionReport={sessionReport}
              />
            )}

            <CohortEventInfo
              forHeader
              subject={subject}
              cohortId={cohortId}
              cohortName={cohortName}
              subSubject={subSubject}
              engagementId={engagementId}
              engagementName={engagementName}
              productType={engagementProductType}
              instructionLevel={instructionLevel}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-center w-full gap-[2px]">
        <div className="bg-cyan-800 w-[98%] rounded-full h-[3px]" />
      </div>
    </div>
  );
};
