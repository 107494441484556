import { gql } from "@apollo/client";
import {
  CohortAssignmentSubject,
  CohortSessionStudentAttendanceStatus,
  TutorDashboardCohortSessionStudentAttendance,
  TutorDashboardCohortSessionStudentGrading,
  TutoringTabDashboardEventDetailsBuilder_TutorDashboardEventFragment,
} from "@generated/graphql";
import {
  elaGradeOptions,
  generalGradeOptions,
  GRADE_TYPE_TO_ABSENT_TYPE_MAP,
} from "components/shared/AttendanceGrades/GradingPanel/constants";
import {
  EvaluationTableDetails,
  StudentEvaluationRow,
} from "components/shared/AttendanceGrades/SessionStudentEvaluationTable";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { getEventScheduleStatus } from "../../utils";
import { EventDetails, TutorTabDashboardEventDetails } from "./types";

tutoringTabDashboardEventDetailsBuilder.fragments = {
  tutorDashboardEvent: gql`
    fragment TutoringTabDashboardEventDetailsBuilder_TutorDashboardEvent on TutorDashboardEvent {
      cacheKey
      endDateTime
      startDateTime
      durationMinutes
      cohortSessionId
    }
  `,
};

export function tutoringTabDashboardEventDetailsBuilder<
  D extends TutoringTabDashboardEventDetailsBuilder_TutorDashboardEventFragment,
  C,
  E,
>(
  ttDashboardEvent: D,
  cohort: C | undefined,
  engagement: E | undefined,
  currentDate: Date
): TutorTabDashboardEventDetails<D, C, E> {
  const startDateTime = new Date(ttDashboardEvent.startDateTime);
  const endDateTime = new Date(ttDashboardEvent.endDateTime);
  const minutesElapsed = differenceInMinutes(currentDate, startDateTime);
  return {
    ...ttDashboardEvent,
    cohort,
    engagement,
    scheduleStatus: getEventScheduleStatus(
      startDateTime,
      endDateTime,
      currentDate
    ),
    minutesElapsed,
    minutesRemaining: ttDashboardEvent.durationMinutes - minutesElapsed,
  };
}

export const getIsEvaluationComplete = (
  gradingEnabled: boolean,
  subject: CohortAssignmentSubject,
  attendance?: TutorDashboardCohortSessionStudentAttendance | null,
  grading?: Partial<TutorDashboardCohortSessionStudentGrading> | null
) => {
  const { Absent, Unknown } = CohortSessionStudentAttendanceStatus;
  if (!attendance || (gradingEnabled && !grading)) return false;
  if (attendance.status === Absent) return true;

  const attendanceIsTaken = attendance.status !== Unknown;

  let gradingIsTaken = !gradingEnabled;

  if (gradingEnabled && grading) {
    const isELA = subject === CohortAssignmentSubject.Ela;
    const gradeOptions = isELA ? elaGradeOptions : generalGradeOptions;

    gradingIsTaken = gradeOptions.every((gradeType) => {
      const absentKey = GRADE_TYPE_TO_ABSENT_TYPE_MAP[gradeType];
      const hasGrade = grading[gradeType] !== null;
      return absentKey === null ? hasGrade : hasGrade || grading[absentKey];
    });
  }

  return attendanceIsTaken && gradingIsTaken;
};

export const getStudentEvaluationDetails = (
  eventDetails: EventDetails
): {
  studentEvaluationRows: StudentEvaluationRow[];
  evaluationDetails: EvaluationTableDetails;
} => {
  const gradingIsEnabled =
    eventDetails.engagement?.enableStudentGrading ?? false;

  const studentEvaluationRows = (eventDetails.cohort?.activeStudents ?? []).map(
    (student) => {
      const studentAttendanceEntry =
        eventDetails.cohortSession?.studentAttendanceEntries.find(
          (entry) => entry.studentId === student.studentId
        ) ?? null;

      const studentGradingEntry =
        eventDetails.cohortSession?.studentGradingEntries.find(
          (entry) => entry.studentId === student.studentId
        ) ?? null;

      return {
        id: eventDetails.cohortSessionId ?? "0",
        fullName: student.fullName,
        studentId: student.studentId,
        startDate: student.startDate,
        removedAt: student.removedAt,
        studentStatus: student.studentStatus,
        attendance: studentAttendanceEntry,
        grading: studentGradingEntry,
        isEvaluationComplete: getIsEvaluationComplete(
          gradingIsEnabled,
          eventDetails.subject,
          studentAttendanceEntry,
          studentGradingEntry
        ),
      };
    }
  );
  return {
    studentEvaluationRows,
    evaluationDetails: {
      subject: eventDetails.subject,
      isGradingEnabled: gradingIsEnabled,
      id: eventDetails.cohortSessionId ?? "",
      startDateTime: eventDetails.startDateTime,
      isCohortNoShowDay: eventDetails.isCohortNoShowDay,
      isEngagementNoShowDay: eventDetails.isEngagementNoShowDay,
    },
  };
};

export const getAttendedCount = (
  studentAttendanceEntries: TutorDashboardCohortSessionStudentAttendance[]
) => {
  const { Partial, Present } = CohortSessionStudentAttendanceStatus;
  const attended = (studentAttendanceEntries ?? []).filter(({ status }) =>
    [Partial, Present].includes(status as CohortSessionStudentAttendanceStatus)
  );
  return attended.length;
};
